<template>
  <div class="pt-80 pb-10 boxBg">
    <div class="mx-100 my-10 pa-30 border-a bgc-w minH">
      <el-row :gutter="50">
        <el-col :span="6" v-for="(item, index) in sysList" :key="index">
          <el-card shadow="always" class="cp ta-c sys-box">
            <div class="py-20" @click="gotoPage(item.path)">
              <img :src="item.img" height="60" alt="">
              <div class="mt-20 fs-24">{{ item.title }}</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
<!--      <el-row :gutter="50">-->
<!--        <el-col :span="6" v-for="(item, index) in sysList" :key="index">-->
<!--          <div class="pcBg cp" @click="gotoPage(item.path)">-->
<!--            <img :src="item.img" height="60" alt="">-->
<!--            <div class="mt-10 fs-24">{{ item.title }}</div>-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-row :gutter="50">-->
<!--        <el-col :span="6" v-for="(item, index) in sysList" :key="index">-->
<!--          <div class="laptopBg cp" @click="gotoPage(item.path)">-->
<!--            <img :src="item.img" height="60" alt="">-->
<!--            <div class="mt-10 fs-22">{{ item.title }}</div>-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
    </div>
  </div>
</template>

<script>
import publicFun from "@/mixins/public";
import interfaceWechat from "@/mixins/interfaceWechat";
export default {
  name: "index",
  mixins: [publicFun, interfaceWechat],
  data() {
    return {
      sysList: [
        {
          title: '我要开票',
          path: 'makeInvoice',
          img: require('@/assets/img/kaipiao.png'),
        },
        {
          title: '云牌付',
          path: 'yunpaifu',
          img: require('@/assets/img/yunpaifu.png'),
        }
      ],
    }
  },
  mounted() {
    if (sessionStorage.userinfo) this.getPro();
  },
  methods: {
    getPro() {
      if (sessionStorage.projectList) {
        this.setList(JSON.parse(sessionStorage.projectList));
        return
      }
      this.$axios
          .post('userManager/login/getProject', {unionId: JSON.parse(sessionStorage.userinfo).unionid})
          .then(res => {
            const {
              code,
              data,
              msg
            } = res
            if (code == 0 && data) {
              sessionStorage.projectList = JSON.stringify(data);
              this.setList(data);
            }
          }).catch(err => {
            console.log(err)
          });
    },
    setList(data) {
      data.forEach(item => {
        if (item.projectId === 'lM4s+l7pExEK7naq0H0wESglpJ4iAsXo') {
        } else if (item.projectId === 'rFrzlD2FuJPuQ2bH6IBtc+6iWH0l34xr') {
          this.sysList.push({
            title: '清分对账',
            path: 'reconciliation',
            img: require('@/assets/img/duizhang.png'),
          });
        } else if (item.projectId === 'zbY/ze7FaEe3KrAX6L3dv16QYjA8s8HI') {
          this.sysList.push({
            title: '电子发票管理系统',
            path: 'e-invoice',
            img: require('@/assets/img/fapiaoM.png'),
          });
        } else if (item.projectId === 'PT95Arq8wxKVqVzAX1dkp4fsYLE/YUSY') {
          this.sysList.push({
            title: '渡报通平台',
            path: 'springdataanalysis',
            img: require('@/assets/img/dubaotong.44b0ac68.png'),
          });
        }
      })
    },
    gotoPage(page) {
      if (this.goLogin()) {
        let ticket = JSON.parse(sessionStorage.userinfo).ticket;
        if (page === 'makeInvoice') {
          this.goPage(page);
        } else if (page === 'yunpaifu') {
          window.open('http://47.94.45.53:10020/#/login?ticket=' + ticket);
        } else if (page === 'reconciliation') {
          window.open('http://47.94.45.53:10013/#/login?ticket=' + ticket);
        } else if (page === 'e-invoice') {
          window.open('http://47.94.45.53:10007/#/login?ticket=' + ticket);
        } else if (page === 'springdataanalysis') {
          window.open('http://47.94.45.53:10003/#/login?ticket=' + ticket);
        } else if (!page) {
          this.$message('敬请期待！');
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.boxBg {
  background-image: url("http://47.94.45.53:10009/image/htmlImg/banner1.png");
  background-size: cover;
}
.minH {
  height: calc(100vh - 80px - 48px - 10px - 20px);
  overflow: hidden;
  position: relative;
}
.sys-box {
  //background-image: url("../../assets/img/sys_bg1.jpg");
  background-size: cover;
  background-position: center;
  //color: #fff;
  //background-color: rgba(0,0,0,0.5);
  //background-blend-mode: multiply;
}
.pcBg {
  background-image: url("../../assets/img/pcbox.png");
  height: 274px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 55px 0;
}
.laptopBg {
  background-image: url("../../assets/img/laptop.png");
  height: 220px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  padding: 55px 0;
}
</style>
